import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './article-preview.module.css'

export default ({ article }) => (
    <div className={styles.preview}>
        <Link to={`/blog/${article.slug}`}>
            <Img alt="" fluid={article.heroImage.fluid} />
            <h3 className={styles.previewTitle}>
                {article.title}
            </h3>
            <small>{article.publishDate}</small>
            <div className={styles.text}
                 dangerouslySetInnerHTML={{
                     __html: article.description.childMarkdownRemark.html,
                 }}
            />
            {article.tags &&
            article.tags.map(tag => (
                <p className={styles.tag} key={tag}>
                    {tag}
                </p>
            ))}
        </Link>
    </div>
)
